
$(document).ready(function() {
    // Init Functions
    tabs();
});

// Functions

function tabs() {
    $('.m-hero__nav .m-hero__nav-item:first').addClass('m-hero__nav-current-item');
    $('.m-hero__nav-item').on('click', function() {
        var tabName = $(this).attr('class').substring(35);
        $('.m-hero__nav-item').removeClass('m-hero__nav-current-item');
        $(this).addClass('m-hero__nav-current-item');
        $('.m-hero__tab').fadeOut(0);
        $('.m-hero__tab--' + tabName).fadeIn(800);
    });
}

var heros = $('.m-hero');
for (var i = 0; i < heros.length; i++) {
  var hero_content = $(heros[i]).find('>.m-hero--deep .m-hero__content');
  var hero_content_height = $(hero_content).outerHeight();
  var hero_image = $(heros[i]).find('>.m-hero__media figure');
  var hero_video = $(heros[i]).find('>.m-hero__media .m-video');
  $(heros[i]).css({minHeight: hero_content_height + 64});
  $(hero_image).css({minHeight: hero_content_height + 64});
  $(hero_video).css({minHeight: hero_content_height + 64});
}

window.onresize = function(event) {
  var heros = $('.m-hero');
  for (var i = 0; i < heros.length; i++) {
    var hero_content = $(heros[i]).find('>.m-hero--deep .m-hero__content');
    var hero_content_height = $(hero_content).outerHeight();
    var hero_image = $(heros[i]).find('>.m-hero__media figure');
  var hero_video = $(heros[i]).find('>.m-hero__media .m-video');
  $(heros[i]).css({minHeight: hero_content_height + 64});
  $(hero_image).css({minHeight: hero_content_height + 64});
  $(hero_video).css({minHeight: hero_content_height + 64});
  }
};