/** =============================================================================
OWL SLIDER
============================================================================ */

$(document).ready(function()
{
  init_sliders();
});

// Pad a number with leading zeroes.
// Helper Funktion um einen Zahlen-Pager zu erstellen ( <- 1 2 3 4 -> )
function pad(n, len) {
  s = n.toString();
  if (s.length < len) {
    s = ('0000000000' + s).slice(-len);
  }

  return s;
}

function init_sliders() {
  rundj_slider01();
}

// S L I D E R

function rundj_slider01() {

  rundj_slider01 = $('.m-slider');

  rundj_slider01.each(function() {

    $wrapper = $(this);
    var owl = $wrapper.find('>.owl-carousel');
    var owl_options_additional = owl.data("owl-carousel");
    var owl_options_items = owl.data("owl-carousel").first;
    
    var owl_options = {
      items: 1,
      stagePadding: 50,
      loop: false,
      rewind: false,
      margin: 0,
      nav: false,
      dots: false,
      smartSpeed: 440,
      lazyLoad: true,
      autoplay: false,
      autoplayTimeout: 4000,
      autoplayHoverPause: true,
      onInitialized  : counter, // When the plugin has initialized.
      onTranslated : counter, // When the translation of the stage has finished.
      navElement: 'span',
      navClass: ['m-slider__nav-prev', 'm-slider__nav-next'],
      navText: [
        '<svg><use xlink:href="#symbol-arrow-left"></use></svg>',
        '<svg><use xlink:href="#symbol-arrow-right"></use></svg>'
      ],
      dotsClass: 'owl-dots m-slider__dots',
      responsiveClass:true,
      responsive:{
        0:{
          items:1
        },
        768:{
          items: owl_options_items
        }
      }
    };
  
    $.extend(owl_options, owl_options_additional);

    // Owl Slider initiieren
    owl.owlCarousel(owl_options);

    // COUNTER

    function counter(event) {
      if (!event.namespace) {
        return;
      }
      var slides = event.relatedTarget;
      $('.m-slider__counter').text(slides.relative(slides.current()) + 1 + '/' + slides.items().length);
    }

  });

}