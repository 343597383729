/**
 * Smooth Scroll-to Links
 * -------------------------------------------------- */

$('a[href^="#"]').addClass('js-scroll-to');
$('a[href^="/#"]').addClass('js-scroll-to');


$('.js-scroll-to').click(function() {
  if (
    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') ||
    location.hostname == this.hostname
  ) {
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    if (target.length) {
      $('html,body').animate(
        {
          scrollTop: target.offset().top
        },
        750
      );
      return false;
    }
  }
});
