/**
 * Open External Links in New Tab/Window
 * --------------------------------------------------
 * https://jakearchibald.com/2016/performance-benefits-of-rel-noopener/
 * -------------------------------------------------- */

$('a[href^="http"], a[href^="https"], a[href^="//"]')
  .not('a[href*="' + window.location.hostname + '"]')
  .attr({
    target: '_blank',
    rel: 'noopener noreferrer'
  });

// $('a[href^="' + window.location.origin + '"]').attr({
//   target: '_self'
// });

$('a[href^="tel"], a[href^="mailto"], a[href^="sms"]').attr({
  rel: 'nofollow'
});